import defaultMeta from '@/meta'

const metaMixin = {
  metaInfo () {
    this.meta = this.meta || {}
    const title = this.meta.title && (this.meta.title !== defaultMeta.title) ? `${this.meta.title} | ${defaultMeta.title}` : defaultMeta.title
    const description = this.meta.description || defaultMeta.description
    const img = this.meta.img || defaultMeta.img
    const type = this.meta.type || defaultMeta.type
    const siteName = this.meta.siteName || defaultMeta.siteName
    const author = this.meta.author || defaultMeta.author
    const keywords = this.meta.keywords || defaultMeta.keywords
    const url = this.$router ? `${defaultMeta.url}${this.$router.currentRoute.path.substring(1)}` : ''
    return {
      title,
      meta: [
        {
          property: 'description',
          content: description,
          vmid: 'description'
        },
        {
          property: 'keywords',
          content: keywords,
          vmid: 'keywords'
        },
        {
          property: 'author',
          content: author,
          vmid: 'author'
        },
        {
          property: 'og:title',
          content: title,
          vmid: 'og:title'
        },
        {
          property: 'og:description',
          content: description,
          vmid: 'og:description'
        },
        {
          property: 'og:image',
          content: img,
          vmid: 'og:image'
        },
        {
          property: 'og:type',
          content: type,
          vmid: 'og:type'
        },
        {
          property: 'og:site_name',
          content: siteName,
          vmid: 'og:site_name'
        },
        {
          property: 'og:url',
          content: url,
          vmid: 'og:url'
        }
      ]
    }
  }
}

export default metaMixin
