import Vue from 'vue'
import Vuex from 'vuex'
import * as types from './mutation-types'
import { MAX_NUMBER_OF_TRIES } from '../constants'
import episodes from '@/api/episodes'
Vue.use(Vuex)

export const mutations = {
  [types.SET_ALL_EPISODES] (state, data) {
    state.allEpisodes = data
  },
  [types.SET_CURRENT_EPISODE] (state, data) {
    state.currentEpisode = data
  },
  [types.SET_CURRENT_AUDIO] (state, data) {
    state.currentAudio = data
  },
  [types.INCREMENT_NUMBER_OF_TRIES] (state) {
    state.getAllEpisodesNumberOfTries++
  },
  [types.SET_ALL_EPISODES_ERROR] (state, data) {
    state.allEpisodesError = data
  }
}

export const actions = {
  async getAllEpisodes ({ commit, dispatch, state }) {
    commit(types.INCREMENT_NUMBER_OF_TRIES)
    if (state.getAllEpisodesNumberOfTries >= MAX_NUMBER_OF_TRIES) {
      commit(types.SET_ALL_EPISODES_ERROR, true)
      return
    }

    try {
      const data = await episodes()
      if (data && data.length > 0) {
        commit(types.SET_ALL_EPISODES, data)
      } else {
        await dispatch('getAllEpisodes')
      }
    } catch (e) {
      console.log(e)
      await dispatch('getAllEpisodes')
    }
  },
  async setCurrentEpisode ({ rootState, commit, dispatch }, { data }) {
    if (!rootState.allEpisodes) {
      await dispatch('getAllEpisodes')
    }
    commit(types.SET_CURRENT_EPISODE, data)
  },
  setCurrentAudio ({ commit }, { data }) {
    commit(types.SET_CURRENT_AUDIO, data)
  }
}

export const getters = {
  allEpisodes (state) {
    return state.allEpisodes
  },
  currentEpisode (state) {
    return state.allEpisodes && state.currentEpisode ? state.allEpisodes.find(episode => episode.id === state.currentEpisode)
      : null
  },
  currentAudio (state) {
    return state.currentAudio
  },
  allEpisodesError (state) {
    return state.allEpisodesError
  }
}

export const state = {
  allEpisodes: null,
  currentEpisode: null,
  currentAudio: null,
  allEpisodesError: null,
  getAllEpisodesNumberOfTries: 0
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})

export default store
