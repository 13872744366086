<template>
  <header>
    <router-link to="/">
      <img alt="The Drunk Web Logo" src="@/assets/img/logo.png">
    </router-link>
  </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>
