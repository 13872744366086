<template>
  <router-link
    :class="{'tile': true, 'tile--unavailable': data.unavailable}"
    :to="data.unavailable ? '/' : `/episode/${data.id}`">
    <div class="tile__foreground">
      <h2 class="tile__number">{{data.id}}</h2>
      <h3 class="tile__title">{{data.title}}</h3>
      <h4 v-if="data.guestName" class="tile__guest">Guest:
        <span class="title_guest-name">{{data.guestName}}</span>
      </h4>
      <h4 v-else class="tile__guest">Solo Episode</h4>
      <h4 class="tile__drink">Drink:
        <span class="title_drink-name">{{data.drink}}</span>
      </h4>
      <h5 class="tile__date">{{data.unavailable ? 'Temporarily Unavailable' : data.date}}</h5>
    </div>
    <div class="tile__background" :style="{'background-image': `url(${data.img})`}"></div>
  </router-link>
</template>

<script>
export default {
  name: 'Tile',
  props: {
    data: Object
  }
}
</script>
