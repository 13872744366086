<template>
  <div
    v-if="episode"
    class="player">
    <div class="player__info">
      <router-link :to="`/episode/${episode.id}`">
        <div
          class="player__info__thumbnail"
          :style="{'background-image': `url(${thumbnail})`}"
          />
      </router-link>
      <div class="player__info__text">
        <h6 class="player__info__heading">Now Playing</h6>
        <h5 class="player__info__title">{{episode.title}}</h5>
      </div>
    </div>
    <audio class="player__audio" controls :src="episode.audio">
    </audio>
    <div
      @click="onClose()"
      class="player__btn--close">
      <span class="icon-close"></span>
    </div>
  </div>
</template>

<script>
import log from '@/logger'

export default {
  name: 'Player',
  props: {
    episode: Object
  },
  computed: {
    thumbnail () {
      return this.episode && this.episode.img ? this.episode.img.replace('guest', 'guest_200x200') : ''
    }
  },
  methods: {
    onClose () {
      this.$parent.$emit('closePlayer')
      log({
        type: 'audio_stop',
        episode: this.episode.id
      })
    }
  }
}
</script>
