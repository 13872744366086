export default {
  apiKey: 'AIzaSyAgMPGmK2xsKU5nHxiQgAcQ9dcCrw8kkOs',
  authDomain: 'the-drunk-web.firebaseapp.com',
  databaseURL: 'https://the-drunk-web.firebaseio.com',
  projectId: 'the-drunk-web',
  storageBucket: 'the-drunk-web.appspot.com',
  messagingSenderId: '32739556882',
  appId: '1:32739556882:web:d07d5aef05e74fa072e2cb',
  measurementId: 'G-CHBYHKQ818'
}
