<template>
  <a
    class="banner"
    href="https://www.patreon.com/thedrunkweb"
    target="_blank">
    Support on <em>Patreon</em>
  </a>
</template>

<script>
export default {
  name: 'Banner'
}
</script>
