export const MAX_NUMBER_OF_TRIES = 3

export const ANALYTICS = {
  SELECT_CONTENT: 'select_content',
  IMAGE: 'image',
  IMAGE_CLOSE: 'image_close',
  AUDIO_START: 'audio_start',
  AUDIO_STOP: 'audio_stop',
  IMAGE_OPEN: 'image_open',
  ERROR: 'error'
}
