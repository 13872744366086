<template>
  <div class="align-center">
    <h1 class="hidden">{{meta.title}}</h1>
    <section>
      <h6>The Drunk Web Podcast is available on</h6>
      <ul class="badges">
        <li
          v-for="badge in badges"
          :key="badge.name"
          class="badge">
          <a
          :href="badge.url"
          target="_blank">
            <img
              :alt="`Listen on ${badge.name}`"
              :src="require(`@/assets/img/${badge.img}`)">
          </a>
        </li>
      </ul>
    </section>

    <section>
      <h6>Support the podcast by becoming a Patron</h6>
      <a
        class="badge"
        href="https://www.patreon.com/thedrunkweb"
        target="_blank">
        <img
          alt="Become a Patron on Patreon"
          src="@/assets/img/patreon.png">
      </a>
    </section>

    <section>
      <h6>or by sharing it with your friends</h6>
      <a
        class="badge"
        href="https://instagram.com/thedrunkweb/"
        target="_blank">
        <img src="@/assets/img/instagram.svg" alt="instagram logo">
      </a>

      <a
        class="badge"
        href="https://facebook.com/thedrunkweb/"
        target="_blank">
        <img src="@/assets/img/facebook.svg" alt="facebook logo">
      </a>

      <a
        class="badge"
        href="https://twitter.com/TheDrunkWeb"
        target="_blank">
        <img src="@/assets/img/twitter.svg" alt="twitter logo">
      </a>
    </section>
  </div>
</template>

<script>
import metaMixin from '@/mixins/meta'

export default {
  name: 'Subscribe',
  data () {
    return {
      meta: {
        title: 'Subscribe'
      }
    }
  },
  mixins: [metaMixin],
  mounted () {
    document.dispatchEvent(new Event('x-app-rendered'))
  },
  computed: {
    badges () {
      return [
        {
          name: 'Spotify',
          url: 'https://open.spotify.com/show/3XDx61P5EhJi6m94MhHfzY',
          img: 'listen-on-spotify.svg'
        },
        {
          name: 'Apple',
          url: 'https://itunes.apple.com/us/podcast/the-drunk-web/id1044518189',
          img: 'listen-on-apple.svg'
        },
        {
          name: 'Anchor',
          url: 'https://anchor.fm/thedrunkweb',
          img: 'listen-on-anchor.png'
        },
        {
          name: 'Google Play',
          url: 'https://playmusic.app.goo.gl/?ibi=com.google.PlayMusic&isi=691797987&ius=googleplaymusic&apn=com.google.android.music&link=https://play.google.com/music/m/I5pbo2bddljiwu7vccfhi6gdzlq?t%3DThe_Drunk_Web%26pcampaignid%3DMKT-na-all-co-pr-mu-pod-16',
          img: 'listen-on-google-play.png'
        },
        {
          name: 'Google Podcasts',
          url: 'https://podcasts.google.com/?q=the%20drunk%20web',
          img: 'listen-on-google-podcasts.svg'
        },
        {
          name: 'Pocket Casts',
          url: 'http://pocketcasts.com',
          img: 'listen-on-pocketcasts.png'
        },
        {
          name: 'Stitcher',
          url: 'http://app.stitcher.com/browse/feed/81207/details',
          img: 'listen-on-stitcher.png'
        }
      ]
    }
  }
}
</script>
