<template>
  <footer>
    <div class="footer-content">
      <ul class="footer-content__links">
        <li><router-link to="/about">About</router-link></li>
        <li><router-link to="/">Episodes</router-link></li>
        <li><router-link to="/subscribe">Subscribe</router-link></li>
        <li>
          <a href="https://www.etsy.com/shop/ColorCodeStore" target="_blank">Merchandise</a>
        </li>
        <li>
          <a
            href="https://www.patreon.com/thedrunkweb"
            target="_blank">
            Support on Patreon
          </a>
        </li>
      </ul>
      <p>Designed and Developed by <a target="_blank" href="https://www.colorcode.io/">ColorCode.io</a></p>
      <p class="footer-content__legal">
        <small>TheDrunkWeb and its owner(s) do not have any liability for publishing any comment or material that may be revealed by third parties, including interviewees, guests and/or their agents. By using TheDrunkWeb material, however accessed, you indemnify TheDrunkWeb and its owner(s) against any claims in relation to the material mentioned, released and revealed by third parties including interviewees, guests and/or any other persons.</small>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
