const prerender = {
  methods: {
    prerender () {
      setTimeout(() => {
        document.dispatchEvent(new Event('x-app-rendered'))
      }, 10)
    }
  }
}

export default prerender
