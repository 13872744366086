<template>
  <div
    v-if="!allEpisodes && !allEpisodesError"
    class="loading-container">
    <span class="icon-beer" aria-label="loading"></span>
  </div>
  <div v-else-if="!allEpisodes && allEpisodesError">
    <Error />
  </div>

  <div v-else>
    <h1 class="hidden">{{meta.title}}</h1>
    <ul>
      <li
        :key="episode.id"
        v-for="episode in allEpisodes">
        <Tile :data="episode"/>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Tile from '@/components/Tile'
import Error from '@/components/Error'
import metaMixin from '@/mixins/meta'
import defaultMeta from '@/meta'

export default {
  name: 'Home',
  components: {
    Error,
    Tile
  },
  mixins: [metaMixin],
  data () {
    return {
      meta: defaultMeta
    }
  },
  methods: {
    ...mapActions([
      'getAllEpisodes'
    ])
  },
  computed: {
    ...mapGetters([
      'allEpisodes',
      'allEpisodesError'
    ])
  },
  async mounted () {
    await this.getAllEpisodes()
    document.dispatchEvent(new Event('x-app-rendered'))
  }
}
</script>
