<template>
  <div class="menu">
    <button aria-label="open menu" class="menu__toggle" @click="onToggle()">
      <span v-if="isOpen" class="icon-close"></span>
      <span v-else class="icon-menu"></span>
    </button>
    <nav :class="{'menu__nav': true, 'menu__nav--open': isOpen}">
      <ul>
        <li>
          <router-link to="/about">🥃 about</router-link>
        </li>
        <li>
          <router-link to="/">🎧 episodes</router-link>
        </li>
        <li>
          <router-link to="/subscribe">🍻 subscribe</router-link>
        </li>
        <li>
          <a href="https://www.etsy.com/shop/ColorCodeStore" target="_blank">👕 merch</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    onToggle () {
      this.isOpen = !this.isOpen
    }
  },
  watch: {
    $route () {
      this.isOpen = false
    }
  }
}
</script>
