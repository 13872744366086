<template>
  <div>
    <h1 class="hidden">{{meta.title}}</h1>
    <h2>What's all this?</h2>
    <p>The Drunk Web is a free podcast, a series of conversations between your host, Sina Jazayeri, and web professional, engineers, coders, designers, managers, execs, CEOs, and all the other wonderful people who make the web every day... Oh and we have a couple of drinks before the show. And during. And after.</p>

    <h2>How do I listen?</h2>
    <p>You can listen to The Drunk Web on <a target="_blank" href="https://itunes.apple.com/us/podcast/the-drunk-web/id1044518189">iTunes Podcast</a>, <a target="_blank" href="https://open.spotify.com/show/3XDx61P5EhJi6m94MhHfzY?si=yCvV0kxTR2C8ul2qxEKWVgor">Spotify</a>, <a target="_blank" href="https://anchor.fm/thedrunkweb">Anchor</a>, <a target="_blank" href="https://playmusic.app.goo.gl/?ibi=com.google.PlayMusic&isi=691797987&ius=googleplaymusic&apn=com.google.android.music&link=https://play.google.com/music/m/I5pbo2bddljiwu7vccfhi6gdzlq?t%3DThe_Drunk_Web%26pcampaignid%3DMKT-na-all-co-pr-mu-pod-16">Google Play</a> or directly on <a href="/">TheDrunkWeb.com</a>. It's free.</p>

    <h2>How can I support?</h2>
    <p>You can become a <a href="https://www.patreon.com/thedrunkweb" target="_blank">Patron</a> to help support the podcast and make sure it continues. Also, you can show your love by <a href="https://www.thedrunkweb.com/subscribe/" target="_blank">subscribing</a> to the mailing list, and telling your friends about TDW.</p>

    <h2>Can I be a guest?</h2>
    <p>Yes. Well, maybe. <a href="https://www.instagram.com/thedrunkweb/" target="_blank">Get in touch on instagram</a> and we'll talk.</p>

    <h2>A message from your host</h2>
    <p>Welcome to The Drunk Web. I am your host <a href="http://sinjaz.com" target="_blank">Sina Jazayeri</a>. You are awesome by the way, for tuning in. I have no idea how to do a podcast, I’ve never done one before so I have a lot of things to figure out, but I am hoping that the quality of my guests and the material itself will make up for some of those things.</p>

    <p>A quick background on me: I live in New York City. I’ve been a software engineer for nearly 15 years. I build web, native and mobile apps, for the most part front end. What I like the most and what I’m good at is where front end engineering meets UX/UI design. I like design software architecture as well as graphics and experiences and there is no better place to do all of those things at once, than the web. So that’s where I ended up. Right now, I’m a Senior Director of Software Engineering at <a href="https://www.inrhythm.com/" target="_blank">InRhythm</a>. I'm also the founder of <a href="https://www.ColorCode.io/" target="_blank">ColorCode.io</a>, a free code school with lots of videos and goodies. It's kewl, check it out! If you wanna know more about me, my website is <a href="http://sinjaz.com" target="_blank">sinjaz.com</a>.</p>

    <p>I realize there are people out there with already great podcasts and shows, great material and insight into the industry. I’m a big fan of <a href="http://thewebahead.net/" target="_blank">The Web Ahead</a> by <a href="http://jensimmons.com/" target="_blank">Jen Simmons</a>, shout out to Jen, <a href="http://shoptalkshow.com/" target="_blank">Shop Talk Show</a> with <a href="http://daverupert.com/" target="_blank">Dave Rupert</a> and <a href="http://chriscoyier.net/" target="_blank">Chris Coyier</a>, also a good one (I mean who doesn’t like Chris!), and plenty others. So very quickly I want to talk about why the world needs yet another Podcast.</p>

    <p>I’ve been lucky enough to meet and work with so many amazing individuals, engineers, designers, strategists, even managers and testers, and often I sit down with them and have conversations that I would have loved to listen to when I was first starting out. Or even now. I think there’s a tremendous amount of value in discussing our industry in a casual setting, with the people who are actively working in it and are making it better every day.</p>

    <p>So a while ago I sat down with an old friend of mine who played a big role in my understanding of browser behavior, specifically core CSS. I wanted to tell him what I’ve been up to, and while chatting at the bar, and I noticed how much the drinks helped the conversation. Apparently I didn’t get the memo on drinks!… So anyway, our conversation turned into something between a technical discussion and <a href="http://www.cc.com/shows/drunk-history" target="_blank">Drunk History</a>. Except not as drunk! I actually recorded it and listened to it the next day and found a lot of interesting discoveries. So I decided to create my own version of a web podcast with this casual and social element thrown into the mix. Maybe one day I can tape the show at bars and lounges around New York, but for now I hope you get something out of these conversations. And thank you so much for tuning in again.</p>

    <p>Thank you for coming by,<br>Sina</p>

    <h2>A special thank you to</h2>
    <p>
      James Wanga, Manny Balbin, Heath Burr, Dana White, Charles Fulnecky, Oli Griffiths, John Savarino, Luke Lappin, Adam Parrish, Patrick Mowrer, John K. Paul, Evan Rose, Chris 'Max' Mathers, JR Palomar, Mike Fisher, Jasmine Merchant, Bob Ouellette, Brent Bevolo, Austin Cornelio, Chris Braithwaite, Ryan Crowe, Jesse Owens, Vasily Myazin, Adam Davies, Richard Bumbury, Ryan Watkins, Sena Heydari, Victor Buzzegoli, Will Schneider, Ben Potter, Seph Cordovano, Aliah Andujo, Jason Decker, Mike Greenfield, Jesse Warden, Metin Senler, Jay Eckert, Josh Villahermosa, Konrad Kopczynski, Hans Telford, Khaled Mohamed, Kalen Imani Norton, and bunch of other very super bad ass people.
    </p>

    <h2>Disclaimer</h2>
    <p>TheDrunkWeb and its owner(s) do not have any liability for publishing any comment or material that may be revealed by third parties, including interviewees, guests and/or their agents. By using TheDrunkWeb material, however accessed, you indemnify TheDrunkWeb and its owner(s) against any claims in relation to the material mentioned, released and revealed by third parties including interviewees, guests and/or any other persons.</p>

  </div>
</template>

<script>
import metaMixin from '@/mixins/meta'

export default {
  name: 'About',
  data () {
    return {
      meta: {
        title: 'About'
      }
    }
  },
  mixins: [metaMixin],
  mounted () {
    document.dispatchEvent(new Event('x-app-rendered'))
  }
}
</script>
