import { db } from '@/firebase'
import { collection, getDocs } from 'firebase/firestore'
import { formatDate } from '@/utils/date'

export default async function episodes () {
  const collectionName = 'e'
  const episodesCollection = await collection(db, collectionName)
  const querySnapshot = await getDocs(episodesCollection)
  return new Promise(function (resolve, reject) {
    let episodes = []
    try {
      querySnapshot.forEach(doc => {
        const episode = doc.data()
        episodes.push({
          ...episode,
          id: doc.id,
          date: formatDate(episode.date)
        })
      })
      episodes = episodes.sort((a, b) => b.id - a.id)
      resolve(episodes)
    } catch (error) {
      reject(error)
    }
  })
}
