<template>
  <div id="app" class="main-container">
    <Header />
    <Menu />
    <Banner />
    <main>
      <router-view></router-view>
    </main>
    <Player :episode="currentAudio" />
    <Footer />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import metaMixin from '@/mixins/meta'

import Banner from './components/Banner.vue'
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'
import Menu from './components/Menu.vue'
import Player from './components/Player.vue'

export default {
  name: 'thedrunkweb',
  components: {
    Banner,
    Footer,
    Header,
    Menu,
    Player
  },
  mixins: [metaMixin],
  created () {
    this.$on('closePlayer', section => {
      this.setCurrentAudio({ data: null })
    })
  },
  methods: {
    ...mapActions([
      'setCurrentAudio'
    ])
  },
  computed: {
    ...mapGetters([
      'currentAudio',
      'currentEpisode'
    ])
  }
}
</script>
