// import firebase from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import firebaseConfig from '@/config/firebase'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'

// Firestore
const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)

// Analytics
const firebaseAnalytics = getAnalytics(app)
export function analytics (event, body) {
  logEvent(firebaseAnalytics, event, body)
}
