// Libs & Framework
import Vue from 'vue'
import VueMeta from 'vue-meta'

// Application
import App from './App.vue'
import store from './store'
import router from './router'

// Styles
import './styles/main.scss'

// Vue Config
Vue.config.productionTip = false
Vue.use(VueMeta, {
  refreshOnceOnNavigation: false
})

// Instantiate App
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
