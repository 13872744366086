<template>
  <div
    v-if="currentPhoto !== null"
    class="gallery">
    <div class="gallery__foreground">
      <button
        @click="onPrev()"
        v-if="!isFirstPhoto()"
        class="gallery__btn--prev icon-left">
        </button>
      <button
        @click="onNext()"
        v-if="!isLastPhoto()"
        class="gallery__btn--next icon-right"></button>
      <button
        @click="onClose()"
        class="gallery__btn--close icon-close">
        </button>
      <img
        v-if="currentPhoto !== -1"
        class="gallery__img"
        :src="photos[currentPhoto]"
        alt="">
    </div>
    <div class="gallery__background">
      <span class="icon-beer"></span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import log from '@/logger'
import { ANALYTICS } from '@/constants'

export default {
  name: 'Gallery',
  props: {
    photos: Array,
    currentPhoto: Number
  },
  mounted () {
    window.addEventListener('keydown', this.onKeydown)
  },
  destroyed () {
    window.removeEventListener('keydown', this.onKeydown)
  },
  computed: {
    ...mapGetters([
      'currentEpisode'
    ])
  },
  methods: {
    isFirstPhoto () {
      return this.currentPhoto === 0
    },
    isLastPhoto () {
      return this.currentPhoto === this.photos.length - 1
    },
    onKeydown ({ keyCode }) {
      switch (keyCode) {
        case 37:
          this.onPrev()
          break
        case 39:
          this.onNext()
          break
        case 27:
          this.onClose()
          break
      }
    },
    onNext () {
      if (this.currentPhoto != null && !this.isLastPhoto()) {
        this.changePhoto(this.currentPhoto + 1)
      }
    },
    onPrev () {
      if (this.currentPhoto != null && !this.isFirstPhoto()) {
        this.changePhoto(this.currentPhoto - 1)
      }
    },
    changePhoto (photoId) {
      this.$emit('update:gallery', -1)
      setTimeout(() => this.$emit('update:gallery', photoId), 10)
      log({
        type: ANALYTICS.IMAGE_OPEN,
        episode: this.currentEpisode.id,
        photo: photoId
      })
    },
    onClose () {
      this.$emit('update:gallery', null)
      log({
        type: ANALYTICS.IMAGE_CLOSE,
        episode: this.currentEpisode.id,
        photo: this.currentPhoto
      })
    }
  }
}
</script>
