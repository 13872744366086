<template>
  <div>
    <div
      v-if="!currentEpisode && !allEpisodesError"
      class="loading-container">
      <span class="icon-beer" aria-label="loading"></span>
    </div>
    <div v-if="!currentEpisode && allEpisodesError">
      <Error />
    </div>

    <div v-if="currentEpisode">
      <Gallery
        v-bind:gallery.sync="currentPhoto"
        :photos="currentEpisode.photos"
        :currentPhoto="currentPhoto"
      />
      <section>
        <h4 class="episode__date">{{currentEpisode.date}}</h4>
        <h1 class="episode__title">{{currentEpisode.id}}. {{currentEpisode.title}}</h1>
        <h2 class="episode__guest">{{currentEpisode.guestName}}</h2>
        <h3 class="episode__drink">{{currentEpisode.drink}}</h3>
        <p v-if="currentEpisode.description" class="episode__description">{{currentEpisode.description}}</p>
        <button
          id="play-btn"
          @click="onClickAudio()"
          :disabled="isCurrentPlaying"
          class="btn">{{playNowWording}}</button>
      </section>

      <section>
        <ul class="episode__photos">
          <li
            :id="'photo--'+index"
            :key="index"
            v-for="(photo, index) in photoThumnails"
            >
            <button
              :aria-label="`episode photo number ${index+1}`"
              @click="onClickPhoto(index)"
              class="episode__photos__item"
              :style="{'background-image': `url(${photo})`}">
            </button>
          </li>
        </ul>
      </section>

      <section>
        <ul>
          <li
            :key="index"
            class="quote"
            v-for="(quote, index) in currentEpisode.quotes">
            <q>{{quote}}</q>
          </li>
        </ul>
      </section>

      <section v-if="currentEpisode.guestBio">
        <div class="guest-card">
          <div class="guest-card__foreground">
            <h3 class="guest-card__title">About the Guest</h3>
            <ul>
              <li
                :key="index"
                class="guest-card__item"
                v-for="(bio, index) in currentEpisode.guestBio">
                <div v-html="bio"></div>
                </li>
            </ul>
          </div>
          <div
            class="guest-card__background"
            :style="{'background-image': `url(${currentEpisode.img})`}"></div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Gallery from '@/components/Gallery'
import Error from '@/components/Error'
import prerender from '@/mixins/prerender'
import metaMixin from '@/mixins/meta'
import store from '@/store'
import log from '@/logger'
import { ANALYTICS } from '@/constants'

export default {
  name: 'Episode',
  components: {
    Error,
    Gallery
  },
  mixins: [
    prerender,
    metaMixin
  ],
  data () {
    return {
      currentPhoto: null
    }
  },
  methods: {
    ...mapActions([
      'setCurrentEpisode',
      'setCurrentAudio'
    ]),
    onClickPhoto (e) {
      this.currentPhoto = e
      log({
        type: ANALYTICS.IMAGE_OPEN,
        episode: this.currentEpisode.id,
        photo: this.currentPhoto
      })
    },
    onClickAudio () {
      this.setCurrentAudio({ data: this.currentEpisode })
      log({ type: ANALYTICS.AUDIO_START, episode: this.currentEpisode.id })
    }
  },
  computed: {
    ...mapGetters([
      'currentEpisode',
      'currentAudio',
      'allEpisodesError'
    ]),
    isCurrentPlaying () {
      return this.currentAudio && this.currentAudio.id === this.currentEpisode.id
    },
    playNowWording () {
      return this.isCurrentPlaying ? 'Playing...' : 'Play Now'
    },
    photoThumnails () {
      return this.currentEpisode ? this.currentEpisode.photos.map(photo => {
        return photo.replace('.jpg', '_200x200.jpg')
      }) : []
    }
  },
  async beforeRouteEnter (to, from, next) {
    await store.dispatch('setCurrentEpisode', { data: to.params.id })
    next()
  },
  async created () {
    this.meta = {
      title: this.currentEpisode ? `${this.currentEpisode.id}: ${this.currentEpisode.title}` + (this.currentEpisode.guestName ? ' with ' + this.currentEpisode.guestName : '') : null,
      description: this.currentEpisode ? `${this.currentEpisode.id}: ${this.currentEpisode.title}` + (this.currentEpisode.guestName ? ' with ' + this.currentEpisode.guestName : '') : null,
      img: this.currentEpisode ? this.currentEpisode.img : null
    }
    this.prerender()
  }
}
</script>
