import { ANALYTICS } from '@/constants'
import { analytics } from '@/firebase'

const events = {
  [ANALYTICS.IMAGE_OPEN]: logImageEvent,
  [ANALYTICS.IMAGE_CLOSE]: logImageEvent,
  [ANALYTICS.AUDIO_START]: logAudioEvent,
  [ANALYTICS.AUDIO_STOP]: logAudioEvent,
  [ANALYTICS.ERROR]: logError
}

/**
 * Logs an image click event
 * @param {Object} data - The details of the event
 * @param {string} data.episode - The episode number where an image of was clicked
 * @param {string} data.photo - The image number that was clicked
 */
function logImageEvent ({ episode, photo, type }) {
  if (type == null || episode == null || photo == null) {
    logError(arguments[0])
    return
  }
  analytics(ANALYTICS.SELECT_CONTENT, {
    content_type: type,
    episode_id: episode,
    photo_id: photo
  })
}

/**
 * Logs an audio event
 * @param {Object} data - The details of the event
 * @param {string} data.type - The type of event: audio_start, audio_stop
 * @param {string} data.episode - The episode number where the audio event occured
 */
function logAudioEvent ({ episode, type }) {
  if (type == null || episode == null) {
    logError(arguments[0])
    return
  }
  analytics(ANALYTICS.SELECT_CONTENT, {
    content_type: type,
    episode_id: episode
  })
}

/**
 * Logs an error event due to bad data
 * @param {Object} data - The details of the error will be passed directly
 */
function logError (data) {
  analytics(ANALYTICS.ERROR, {
    data
  })
}

export default function (params) {
  try {
    events[params.type](params)
  } catch (error) {
    logError(arguments[0])
  }
}
