export default {
  siteName: 'The Drunk Web Podcast',
  title: 'The Drunk Web Podcast!',
  description: 'The Drunk Web is a free podcast, a series of conversations between your host, Sina Jazayeri, and web professional, engineers, coders, designers, managers, execs, CEOs, and all the other wonderful people who make the web every day. Oh and we have a couple of drinks before the show. And during. And after.',
  img: 'https://firebasestorage.googleapis.com/v0/b/the-drunk-web.appspot.com/o/assets%2Flogo.jpg?alt=media&token=946d80ba-6cb0-4ff7-a454-485722b1d398',
  url: 'https://www.thedrunkweb.com/',
  type: 'website',
  author: 'Sina Jazayeri, The Drunk Web Podcast',
  keywords: 'The Drunk Web, Podcast, Development, Engineering, Coding, Drinking, Podcasting, HTML, CSS, JavaScript'
}
