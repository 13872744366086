<template>
  <div class="align-center">
    <h3>Oops! Looks like our servers are down. We've been notified.</h3>
    <h2><a class="underlined" href="https://open.spotify.com/show/3XDx61P5EhJi6m94MhHfzY">Listen on Spotify instead</a></h2>
  </div>
</template>

<script>
export default {
  name: 'Error'
}
</script>
