import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/pages/Home'
import About from '@/pages/About'
import Subscribe from '@/pages/Subscribe'
import Episode from '@/pages/Episode'
Vue.use(VueRouter)

export const routes = [
  {
    path: '',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: Subscribe
  },
  {
    path: '/episode/:id',
    name: 'Episode',
    component: Episode
  }
]

export const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}

const router = new VueRouter({
  mode: 'history',
  scrollBehavior,
  routes
})

export default router
